import { useCallback } from "react"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { useMetaPixel } from "~/hooks/analytics/useMetaPixel"
import { usePinterest } from "~/hooks/analytics/usePinterest"
import { useSnapPixel } from "~/hooks/analytics/useSnapPixel"
import { useTiktokPixel } from "~/hooks/analytics/useTiktokPixel"

export const useLogEmail = () => {
  const logMp = useMetaPixel()
  const logTtp = useTiktokPixel()
  const logPin = usePinterest()
  const logSnap = useSnapPixel()
  const log = useAmplitude()

  return useCallback(
    (email: string) => {
      if (email) {
        logMp.logContact(email)
        logTtp.identify({ email })
        logTtp.completeRegistration()
        logMp.logCompleteRegistration()
        logPin.completeRegistration()
        logSnap.completeRegistration({ email })
        log.logSignUp()
      }
    },
    [logMp, logTtp, logPin, logSnap, log]
  )
}
