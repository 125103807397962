// source https://www.gmass.co/domains
// https://w3techs.com/technologies/overview/email_server
// https://realsender.com/email-trends/2020-email-providers/

// order by popularity. Order is important for the suggestedDomains function
export const domains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "outlook.com",
  "comcast.net",
  "icloud.com",
  "msn.com",
  "hotmail.co.uk",
  "sbcglobal.net",
  "live.com",
  "yahoo.co.in",
  "me.com",
  "att.net",
  "bellsouth.net",
  "rediffmail.com",
  "cox.net",
  "yahoo.co.uk",
  "verizon.net",
  "ymail.com",
  "hotmail.it",
  "kw.com",
  "yahoo.com.tw",
  "mac.com",
  "live.se",
  "live.nl",
  "yahoo.com.br",
  "googlemail.com",
  "libero.it",
  "web.de",
  "allstate.com",
  "btinternet.com",
  "online.no",
  "yahoo.com.au",
  "live.dk",
  "earthlink.net",
  "yahoo.fr",
  "yahoo.it",
  "gmx.de",
  "hotmail.fr",
  "shawinc.com",
  "yahoo.de",
  "moe.edu.sg",
  "163.com",
  "naver.com",
  "bigpond.com",
  "statefarm.com",
  "remax.net",
  "rocketmail.com",
  "live.no",
  "yahoo.ca",
  "bigpond.net.au",
  "hotmail.se",
  "gmx.at",
  "live.co.uk",
  "mail.com",
  "yahoo.in",
  "qq.com",
  "charter.net",
  "indeedemail.com",
  "alice.it",
  "hotmail.de",
  "bluewin.ch",
  "optonline.net",
  "wp.pl",
  "yahoo.es",
  "hotmail.no",
  "pindotmedia.com",
  "orange.fr",
  "live.it",
  "yahoo.co.id",
  "yahoo.no",
  "hotmail.es",
  "morganstanley.com",
  "wellsfargo.com",
  "juno.com",
  "wanadoo.fr",
  "facebook.com",
  "edwardjones.com",
  "yahoo.se",
  "fema.dhs.gov",
  "rogers.com",
  "yahoo.com.hk",
  "live.com.au",
  "nic.in",
  "nab.com.au",
  "ubs.com",
  "uol.com.br",
  "shaw.ca",
  "t-online.de",
  "umich.edu",
  "westpac.com.au",
  "yahoo.com.mx",
  "yahoo.com.sg",
  "farmersagent.com",
  "anz.com",
  "yahoo.dk",
  "dhs.gov",
  "126.com",
  "sina.com",
  // our domains
  "pora.ai",
  "lovi.care",
]
