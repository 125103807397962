import React, { FC, ReactNode } from "react"
import { Box, Flex, Heading, Link } from "@chakra-ui/react"

import type { NextPageCb } from "~/hooks/useNextQuiz"
import { NextButton } from "~/components/shared/NextButton"
import { EmailInputFormControl, useEmailInputHandlers } from "~/components/shared/EmailInput"
import { transformText } from "~/utils/transformText"

import { LetterIcon } from "./Icons"

const ExternalLink: FC<{ href: string; children: ReactNode }> = ({ href, children }) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <Link
    href={href}
    target="_blank"
    textStyle="Paragraph/Tertiary"
    textDecoration="underline"
    color="Base/baseSecondary"
    _activeLink={{ color: "Base/baseSecondary" }}
  >
    {" "}
    {children}
  </Link>
)

type LinkData = {
  title: string
  url: string
}
export const EmailInputPage: FC<{
  title: string
  description: string
  additional_info: string
  links: LinkData[]
  next: NextPageCb
}> = ({ title, description, additional_info, links, next }) => {
  const { isLoading, isInvalid, isEmailValid, onSubmit, onInputType } = useEmailInputHandlers(next)
  return (
    <Flex direction="column" alignItems="center" padding={8}>
      <LetterIcon color="Base/accentPrimary" marginTop="50px" marginBottom="20px" />
      <Heading
        as="h1"
        size="Header/Primary"
        textAlign="center"
        dangerouslySetInnerHTML={{ __html: transformText(title) }}
      />
      {description && (
        <Box
          textStyle="Paragraph/Primary"
          color="Base/baseSecondary"
          textAlign="center"
          marginTop={3}
          dangerouslySetInnerHTML={{ __html: transformText(description) }}
        />
      )}
      <Flex as="form" marginTop={8} w="100%" direction="column" gap={3}>
        <EmailInputFormControl
          isInvalid={isInvalid}
          isLoading={isLoading}
          onInputType={onInputType}
          placeholder="Enter your email"
          errorMessage="This email address is already used — try another one that you use."
        />

        <NextButton
          type="submit"
          onClick={onSubmit}
          isDisabled={!isEmailValid}
          isLoading={isLoading}
          loadingText="Checking your email..."
        />
      </Flex>
      <Box
        textStyle="Paragraph/Tertiary"
        textAlign="center"
        color="Base/baseSecondary"
        marginTop={6}
        marginBottom={4}
        dangerouslySetInnerHTML={{ __html: transformText(additional_info) }}
      />
      <Flex direction="row" gap={3}>
        {links.map(({ title, url }, i) => (
          <ExternalLink key={i} href={url}>
            {title}
          </ExternalLink>
        ))}
      </Flex>
    </Flex>
  )
}
