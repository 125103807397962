import React, { FC } from "react"
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
} from "@chakra-ui/react"

import { noop } from "~/utils"
import { VFlex } from "~/components/shared/VFlex"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { NextButton } from "~/components/shared/NextButton"
import { EmailInputFormControl, useEmailInputHandlers } from "~/components/shared/EmailInput"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { SkinIssues } from "~/generated/paywall"

import { FaceScannerResultContent } from "./FaceScannerResultPage"
import avatarsSrc from "./assets/avatars.webp"
import { ReactComponent as ShieldSvg } from "./assets/shield.svg"

const EmailFormContent: FC<{ next: NextPageCb }> = ({ next }) => {
  const { isLoading, isInvalid, isEmailValid, onSubmit, onInputType } = useEmailInputHandlers(next)

  return (
    <VFlex as="form" gap={6} placeItems="center" textAlign="center">
      <VFlex gap={3} w="full">
        <Heading as="h3" size="Header/MonospacedSecondary">
          Keep your results
        </Heading>
        <Box textStyle="Paragraph/Secondary">
          To ensure that the results are saved in your account.
        </Box>
      </VFlex>
      <VFlex gap={3} w="full" placeItems="center">
        <EmailInputFormControl
          isInvalid={isInvalid}
          isLoading={isLoading}
          onInputType={onInputType}
          placeholder="Enter your email"
          errorMessage="This email address is already used – try another one"
        />

        <Flex textStyle="Paragraph/Secondary">
          <Icon as={ShieldSvg} w="20px" h="20px" color="Other/Green" marginRight="2px" />
          <Box textAlign="left" color="Base/baseSecondary" sx={{ textWrap: "pretty" }}>
            We protect your privacy and are committed to protecting your personal data. We never
            send spam emails, only relevant information.
          </Box>
        </Flex>
      </VFlex>
      <VFlex gap={3} w="full" placeItems="center">
        <NextButton
          type="submit"
          onClick={onSubmit}
          isDisabled={!isEmailValid}
          isLoading={isLoading}
          loadingText="Checking your email..."
          label="Continue"
        />

        <Flex textStyle="Paragraph/Secondary">
          <Image
            src={avatarsSrc}
            w="48px"
            h="20px"
            alt=""
            display="inline-block"
            marginRight="1ex"
          />
          100 000+ people have joined us!
        </Flex>
      </VFlex>
      <Box
        textStyle="Paragraph/Tertiary"
        color="Base/baseSecondary"
        w="full"
        sx={{ "& :link": { color: "black" } }}
      >
        By submitting your email, you&nbsp;agree to&nbsp;our&nbsp;
        <Link target="_blank" href="https://lovi.care/terms/">
          Terms
        </Link>{" "}
        and&nbsp;
        <Link target="_blank" href="https://lovi.care/privacy-policy/">
          Privacy
        </Link>
        . Contact&nbsp;<Link href="mailto:care@lovi.care">care@lovi.care</Link> with&nbsp;questions.
      </Box>
    </VFlex>
  )
}

export const FaceScannerResultWithEmailInputPage: FC<{
  issues: SkinIssues[]
  next: NextPageCb
}> = ({ issues, next }) => (
  <BasePageWrapper minH="full" overflowY="auto" paddingX={0} bgColor="white">
    <FaceScannerResultContent issues={issues} />

    <Drawer isOpen placement="bottom" onClose={noop}>
      <DrawerOverlay bg="extra.BlackAlpha50" backdropFilter="blur(6px)" />
      <DrawerContent bg="none" placeItems="center">
        <Flex
          bg="Base/neutralPrimary"
          padding={6}
          paddingBottom={10}
          borderTopRadius="brand24"
          maxWidth="450px"
          w="full"
        >
          <DrawerBody padding={0} margin={0}>
            <EmailFormContent next={next} />
          </DrawerBody>
        </Flex>
      </DrawerContent>
    </Drawer>
  </BasePageWrapper>
)
