import React, { FC, MouseEventHandler, useCallback } from "react"
import { Button, ButtonProps } from "@chakra-ui/react"

import { useSelector } from "~/hooks/redux"
import { useQuizHistory } from "~/hooks/useQuizHistory"
import { useGoogleAnalytics } from "~/hooks/analytics/useGoogleAnalytics"
import { getNextButtonLabel, selectIsQuizLoading } from "~/store/survey/selectors"

type BP = Omit<ButtonProps, "onClick">
export const NextButton: FC<
  {
    label?: string
    onClick: (a?: string[]) => void
  } & BP
> = ({ variant = "next", type, onClick, isDisabled = false, label: _label, ...rest }) => {
  const {
    params: { quiz: quizId, question: questionId },
  } = useQuizHistory()
  const selectorLabel = useSelector(getNextButtonLabel(quizId!, questionId!))
  const isQuizLoading = useSelector(selectIsQuizLoading)
  const label = _label ?? selectorLabel
  const { track } = useGoogleAnalytics()
  //TODO:implement single way for tracking url for back and next button
  const clickHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      onClick()
      track("click", { Next_quiz: questionId ?? "" })
      e.preventDefault()
    },
    [questionId, onClick]
  )

  return (
    <Button
      w="100%"
      minH="56px"
      variant={variant}
      type={type}
      isDisabled={isQuizLoading || isDisabled}
      onClick={clickHandler}
      {...rest}
    >
      {label}
    </Button>
  )
}
