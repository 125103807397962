import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  Item,
} from "@choc-ui/chakra-autocomplete"
import { FormControl, FormErrorMessage, InputProps, SlideFade } from "@chakra-ui/react"

import { apiValidateEmail } from "~/api"
import type { NextPageCb } from "~/hooks/useNextQuiz"
import { useQuizHistory, useUserId } from "~/hooks/useQuizHistory"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { noop } from "~/utils"

import { validateEmail } from "./validateEmail"
import { domains } from "./domains"
import { useLogEmail } from "./useLogEmail"

const suggestedVariants = (s: string, opt = { count: 5 }) => {
  const lower = s?.toLocaleLowerCase()

  if (!lower.includes("@")) {
    return []
  }

  const [username, host = ""] = lower.split("@")

  const starts = []
  const includes = []

  let total = 0
  for (const domain of domains) {
    if (domain.startsWith(host)) {
      starts.push(domain)
      total++
    } else if (domain.includes(host)) {
      includes.push(domain)
      total++
    }
    if (total >= opt.count) {
      break
    }
  }
  const result = [...starts, ...includes].map((domain) => `${username}@${domain}`)

  if (result.length === 1 && lower === result[0]) {
    return []
  }

  return result
}

/* May be use <datalist /> for suggestion https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#examples */
export const EmailInput: FC<
  Omit<InputProps, "onChange"> & { onChange?: (value: string) => void }
> = ({ onChange = noop, ...props }) => {
  const [suggestion, setSuggestion] = useState<Array<string>>([])

  const _shouldRenderSuggestions = (value: string) => {
    const variants = value.includes("@") ? suggestedVariants(value) : []
    setSuggestion(variants)
    onChange(value)
    return variants.length > 0
  }

  const _onSelectOption = useCallback(
    ({ item }: { item: Item }) => {
      onChange(item.value)
      setSuggestion([])
    },
    [setSuggestion, onChange]
  )

  return (
    <AutoComplete
      disableFilter={true}
      suggestWhenEmpty={false}
      emptyState={false}
      onSelectOption={_onSelectOption}
      closeOnSelect
      placement="bottom"
      shouldRenderSuggestions={_shouldRenderSuggestions}
    >
      <AutoCompleteInput
        placeholder="Email"
        type="email"
        autocomplete="email"
        {...props}
        borderRadius="32px"
        variant="outline"
        textStyle="Paragraph/Primary"
        padding={5}
        height="auto"
        _placeholder={undefined}
        bg="white"
        borderWidth="1px"
        borderColor="Base/baseTertiary"
        errorBorderColor="Other/Error"
        boxShadow="none"
        _disabled={{
          bg: "Base/neutralSecondary",
          color: "Base/baseDisabled",
        }}
        _hover={{
          borderColor: "Base/accentPrimary",
          boxShadow: "none",
        }}
        _focusVisible={{
          borderColor: "Base/accentPrimary",
          boxShadow: "none",
        }}
        _invalid={{
          borderColor: "Other/Error",
          boxShadow: "none",
        }}
      />
      <AutoCompleteList
        borderRadius="brand24"
        boxShadow="none"
        padding={0}
        marginTop="-2px"
        outline="1px solid"
        outlineColor="Base/neutralDisabled"
        outlineOffset="-1px"
      >
        {suggestion.map((item) => (
          <AutoCompleteItem
            key={`option-${item}`}
            value={item}
            textStyle="Paragraph/Primary"
            _focus={{ bgColor: "Base/neutralSecondary" }}
            marginX={0}
            paddingX={5}
            paddingY={3}
            borderRadius={0}
          >
            {item}
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  )
}

const defaultUserEmail = ""
export const EmailInputFormControl: FC<{
  onInputType?: (s: string) => void
  placeholder?: string
  isInvalid?: boolean
  isLoading?: boolean
  errorMessage?: ReactNode
}> = ({
  onInputType = noop,
  placeholder,
  isInvalid = false,
  isLoading = false,
  errorMessage = "Please enter a valid email address",
}) => (
  <FormControl isInvalid={isInvalid}>
    <EmailInput
      placeholder={placeholder}
      onChange={onInputType}
      //defaultValue={defaultUserEmail}
      isDisabled={isLoading}
      isInvalid={isInvalid}
    />
    {isInvalid && (
      <SlideFade in={true}>
        <FormErrorMessage textStyle="Subtitle/Tertiary">{errorMessage}</FormErrorMessage>
      </SlideFade>
    )}
  </FormControl>
)

type ValidateStatus = "INIT" | "LOADING" | "VALID" | "ERROR"
export const useEmailInputHandlers = (next: NextPageCb) => {
  const { userId } = useUserId()
  const {
    params: { quiz, question },
  } = useQuizHistory()

  const [email, setEmail] = useState<string>(defaultUserEmail)
  const [capturedInvalidEmail, setCapturedInvalidEmail] = useState<string>()
  const [isEmailValid, setIsEmailValid] = useState(Boolean(defaultUserEmail))
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>("INIT")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const log = useAmplitude()
  const logEmail = useLogEmail()

  useEffect(() => {
    const loading = ["LOADING", "VALID"].includes(validateStatus)
    setIsLoading((isLoading) => (isLoading !== loading ? loading : isLoading))
  }, [setIsLoading, validateStatus])

  const onSubmit = useCallback(() => {
    if (userId && quiz && question && email) {
      setValidateStatus("LOADING")
      log.validateEmail("loading")
      apiValidateEmail(userId, quiz, question, email)
        .then(() => {
          logEmail(email)

          log.validateEmail("success")
          next([email])
          setValidateStatus("VALID")
        })
        .catch((e) => {
          console.error(e)
          log.validateEmail("fail")
          setValidateStatus("ERROR")
          setCapturedInvalidEmail(email)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next, email, userId, quiz, question])

  const onInputType = useCallback(
    (value: string) => {
      setIsEmailValid(validateEmail(value))
      setEmail(value)
    },
    [setIsEmailValid, setEmail]
  )
  const isInvalid = useMemo(
    () => validateStatus === "ERROR" && email === capturedInvalidEmail,
    [validateStatus, email, capturedInvalidEmail]
  )

  return {
    onInputType,
    isInvalid,
    isLoading,
    onSubmit,
    isEmailValid,
  }
}
